import React from 'react';
import AddScheduleForm from '../components/AddScheduleForm';
import '../css/AddSchedule.css';

function AddSchedule() {
  return (
    <div>
      <h2>Add New Schedule</h2>
      <AddScheduleForm />
    </div>
  );
}

export default AddSchedule;