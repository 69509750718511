import React from 'react';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { FaHome, FaPlus, FaUser, FaSignOutAlt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import '../css/Sidebar.css';

function AppSidebar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const token = localStorage.getItem('token');
  if (!token) {
    return null; // Ne pas afficher la sidebar si l'utilisateur n'est pas connecté
  }

  return (
    <ProSidebar width="200px" collapsed={false}>
  <Menu>
    <MenuItem icon={<FaHome />}>
      Home
      <Link to="/home" />
    </MenuItem>
    <MenuItem icon={<FaPlus />}>
      Add Schedule
      <Link to="/add-schedule" />
    </MenuItem>
    <MenuItem icon={<FaUser />}>
      Account
      <Link to="/account" />
    </MenuItem>
    <MenuItem icon={<FaSignOutAlt />} onClick={handleLogout}>
      Logout
    </MenuItem>
  </Menu>
</ProSidebar>

  );
}

export default AppSidebar;