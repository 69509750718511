// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}

.schedule-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.schedule-card {
  padding: 15px;
}

.schedule-actions {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
}

.MuiPagination-root {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/css/Home.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".home-container {\n  max-width: 900px;\n  margin: 0 auto;\n  padding: 20px;\n}\n\n.schedule-list {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.schedule-card {\n  padding: 15px;\n}\n\n.schedule-actions {\n  margin-top: 10px;\n  display: flex;\n  justify-content: flex-start;\n}\n\n.MuiPagination-root {\n  margin-top: 20px;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
