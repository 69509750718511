// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.account-container h2 {
  margin-bottom: 20px;
}

.account-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.account-form input,
.account-form button {
  margin: 10px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
}

.account-form button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.account-form button:hover {
  background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/css/Account.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,gBAAgB;AAClB;;AAEA;;EAEE,YAAY;EACZ,aAAa;EACb,eAAe;EACf,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".account-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 20px;\n}\n\n.account-container h2 {\n  margin-bottom: 20px;\n}\n\n.account-form {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  max-width: 400px;\n}\n\n.account-form input,\n.account-form button {\n  margin: 10px;\n  padding: 10px;\n  font-size: 16px;\n  width: 100%;\n}\n\n.account-form button {\n  background-color: #007bff;\n  color: white;\n  border: none;\n  cursor: pointer;\n}\n\n.account-form button:hover {\n  background-color: #0056b3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
