// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Main App Layout CSS: App.css */

/* Ensure the entire app layout takes full height */
.App {
  display: flex;
  min-height: 100vh; /* Take up the full height of the screen */
  margin: 0;
  padding: 0;
}

/* Main content */
.main-content {
  padding: 20px;
  background-color: #f4f4f4;
  width: 100%; /* Take the remaining width */
  box-sizing: border-box; /* Include padding in width calculations */
}

/* General styling for headers and main sections */
.App-header {
  background-color: #1f2532;
  color: white;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.content {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 100vh;
}

/* Body settings */
body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,iCAAiC;;AAEjC,mDAAmD;AACnD;EACE,aAAa;EACb,iBAAiB,EAAE,0CAA0C;EAC7D,SAAS;EACT,UAAU;AACZ;;AAEA,iBAAiB;AACjB;EACE,aAAa;EACb,yBAAyB;EACzB,WAAW,EAAE,6BAA6B;EAC1C,sBAAsB,EAAE,0CAA0C;AACpE;;AAEA,kDAAkD;AAClD;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,uCAAuC;EACvC,kBAAkB;EAClB,aAAa;AACf;;AAEA,kBAAkB;AAClB;EACE,YAAY;EACZ,SAAS;EACT,UAAU;EACV,kBAAkB,EAAE,iCAAiC;AACvD","sourcesContent":["/* Main App Layout CSS: App.css */\n\n/* Ensure the entire app layout takes full height */\n.App {\n  display: flex;\n  min-height: 100vh; /* Take up the full height of the screen */\n  margin: 0;\n  padding: 0;\n}\n\n/* Main content */\n.main-content {\n  padding: 20px;\n  background-color: #f4f4f4;\n  width: 100%; /* Take the remaining width */\n  box-sizing: border-box; /* Include padding in width calculations */\n}\n\n/* General styling for headers and main sections */\n.App-header {\n  background-color: #1f2532;\n  color: white;\n  text-align: center;\n  padding: 20px;\n  margin-bottom: 20px;\n  border-radius: 5px;\n}\n\n.content {\n  background-color: #fff;\n  padding: 20px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n  height: 100vh;\n}\n\n/* Body settings */\nbody, html {\n  height: 100%;\n  margin: 0;\n  padding: 0;\n  overflow-x: hidden; /* Prevent horizontal scrolling */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
