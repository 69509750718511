import React from 'react';
import EditScheduleForm from '../components/EditScheduleForm';
import '../css/EditSchedule.css';

function EditSchedule() {
  return (
    <div className="edit-schedule-container">
      <h2>Edit Schedule</h2>
      <EditScheduleForm />
    </div>
  );
}

export default EditSchedule;