import React, { useState, useEffect } from 'react';
import axiosInstance from '../interceptor/axiosConfig';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardContent, Typography, Pagination } from '@mui/material';
import '../css/Home.css';

function Home() {
  const [schedules, setSchedules] = useState([]);
  const [currentPage, setCurrentPage] = useState(() => {
    // Initialiser avec la page enregistrée dans le localStorage ou à 1
    const savedPage = localStorage.getItem('currentPage');
    return savedPage ? parseInt(savedPage, 10) : 1;
  });
  const schedulesPerPage = 3; 
  const navigate = useNavigate();

  useEffect(() => {
    fetchSchedules();
  }, [currentPage]);

  useEffect(() => {
    // Sauvegarder la page actuelle dans le localStorage
    localStorage.setItem('currentPage', currentPage);
  }, [currentPage]);

  const fetchSchedules = async () => {
    try {
      const response = await axiosInstance.get('/schedule');
      setSchedules(response.data);
    } catch (error) {
      console.error('Error fetching schedules:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/schedule/${id}`);
      setSchedules(schedules.filter((schedule) => schedule.id !== id));
    } catch (error) {
      console.error('Error deleting schedule:', error);
    }
  };

  const indexOfLastSchedule = currentPage * schedulesPerPage;
  const indexOfFirstSchedule = indexOfLastSchedule - schedulesPerPage;
  const currentSchedules = schedules.slice(indexOfFirstSchedule, indexOfLastSchedule);

  const paginate = (event, value) => setCurrentPage(value);

  return (
    <div className="home-container">
      <Typography variant="h4" component="h2" align="center" gutterBottom>
        Today's and Upcoming Schedules
      </Typography>
      <Link to="/add-schedule">
        <Button variant="contained" color="primary" style={{ marginBottom: '20px' }}>
          Add Schedule
        </Button>
      </Link>

      <div className="schedule-list">
        {currentSchedules.map((schedule) => (
          <Card key={schedule.id} className="schedule-card" elevation={3}>
            <CardContent>
              <Typography variant="body1">
                {schedule.Song ? `${schedule.Song.title} - ` : ''} {schedule.scheduled_date} - {schedule.start_time} to {schedule.end_time}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {schedule.mount_url}
              </Typography>
              <div className="schedule-actions">
                <Button variant="outlined" color="primary" onClick={() => navigate(`/edit-schedule/${schedule.id}`)}>
                  Edit
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => handleDelete(schedule.id)} style={{ marginLeft: '10px' }}>
                  Delete
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      <Pagination
        count={Math.ceil(schedules.length / schedulesPerPage)}
        page={currentPage}
        onChange={paginate}
        color="primary"
        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
      />
    </div>
  );
}

export default Home;