import React, { useState } from 'react';
import axiosInstance from '../interceptor/axiosConfig';
import '../css/Account.css';

function Account() {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put('/account/me', formData);
      alert('Account updated successfully');
    } catch (error) {
      console.error('Error updating account:', error);
    }
  };

  return (
    <div>
      <h2>Account Settings</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="username"
          placeholder="New Username"
          value={formData.username}
          onChange={handleChange}
        />
        <input
          type="password"
          name="password"
          placeholder="New Password"
          value={formData.password}
          onChange={handleChange}
        />
        <button type="submit">Update Account</button>
      </form>
    </div>
  );
}

export default Account;