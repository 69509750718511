// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Home from './pages/Home';
import AddSchedule from './pages/AddSchedule';
import EditSchedule from './pages/EditSchedule';
import Account from './pages/Account';
import AppSidebar from './components/Sidebar';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <AppSidebar />
        <div className="main-content">
          <header className="App-header">
            <h1>Radio Scheduler</h1>
          </header>
          <main className="content">
            <Routes>
              {/* Route publique protégée */}
              <Route path="/login" element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              } />

              {/* Routes privées protégées */}
              <Route path="/home" element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              } />
              <Route path="/add-schedule" element={
                <PrivateRoute>
                  <AddSchedule />
                </PrivateRoute>
              } />
              <Route path="/edit-schedule/:id" element={
                <PrivateRoute>
                  <EditSchedule />
                </PrivateRoute>
              } />
              <Route path="/account" element={
                <PrivateRoute>
                  <Account />
                </PrivateRoute>
              } />

              {/* Redirection par défaut */}
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </main>
        </div>
      </div>
    </Router>
  );
}

export default App;
