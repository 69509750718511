// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AddSchedule.css */

/* Container for the whole form */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Input fields */
form input,
form select {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border 0.3s ease-in-out;
}

form input:focus,
form select:focus {
  border: 1px solid #007bff;
  outline: none;
}

/* Button */
form button {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

form button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Title for the form */
h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 26px;
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/css/AddSchedule.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB,iCAAiC;AACjC;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA,iBAAiB;AACjB;;EAEE,WAAW;EACX,aAAa;EACb,cAAc;EACd,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;EACtB,mCAAmC;AACrC;;AAEA;;EAEE,yBAAyB;EACzB,aAAa;AACf;;AAEA,WAAW;AACX;EACE,WAAW;EACX,aAAa;EACb,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,0EAA0E;AAC5E;;AAEA;EACE,yBAAyB;EACzB,yCAAyC;AAC3C;;AAEA,uBAAuB;AACvB;EACE,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,WAAW;AACb","sourcesContent":["/* AddSchedule.css */\n\n/* Container for the whole form */\nform {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  max-width: 600px;\n  margin: 0 auto;\n  padding: 30px;\n  background-color: #fff;\n  border-radius: 10px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n/* Input fields */\nform input,\nform select {\n  width: 100%;\n  padding: 12px;\n  margin: 10px 0;\n  font-size: 16px;\n  border: 1px solid #ccc;\n  border-radius: 8px;\n  box-sizing: border-box;\n  transition: border 0.3s ease-in-out;\n}\n\nform input:focus,\nform select:focus {\n  border: 1px solid #007bff;\n  outline: none;\n}\n\n/* Button */\nform button {\n  width: 100%;\n  padding: 12px;\n  font-size: 18px;\n  background-color: #007bff;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  cursor: pointer;\n  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;\n}\n\nform button:hover {\n  background-color: #0056b3;\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);\n}\n\n/* Title for the form */\nh2 {\n  text-align: center;\n  margin-bottom: 20px;\n  font-size: 26px;\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
