// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Sidebar CSS: Sidebar.css */

/* Wrapper for the sidebar */
.ProSidebar {
  position: fixed; /* Sidebar stays fixed */
  top: 0;
  left: 0;
  width: 200px; /* Sidebar width */
  height: 100vh; /* Full height of the viewport */
  background-color: #111; /* Dark background */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Place logout at the bottom */
  z-index: 100; /* Ensure sidebar stays above other content */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Shadow for better visibility */
}

/* Sidebar menu items */
.ProSidebar .menu-item {
  padding: 15px;
  color: #fff;
  text-align: left;
  transition: background-color 0.3s ease;
}

/* Hover effect on sidebar items */
.ProSidebar .menu-item:hover {
  background-color: #3b3f47;
  cursor: pointer;
}

/* Logout button styling (if specific) */
.ProSidebar .menu-item.logout {
  margin-top: auto; /* Push logout to the bottom */
}
`, "",{"version":3,"sources":["webpack://./src/css/Sidebar.css"],"names":[],"mappings":"AAAA,6BAA6B;;AAE7B,4BAA4B;AAC5B;EACE,eAAe,EAAE,wBAAwB;EACzC,MAAM;EACN,OAAO;EACP,YAAY,EAAE,kBAAkB;EAChC,aAAa,EAAE,gCAAgC;EAC/C,sBAAsB,EAAE,oBAAoB;EAC5C,aAAa;EACb,sBAAsB;EACtB,8BAA8B,EAAE,+BAA+B;EAC/D,YAAY,EAAE,6CAA6C;EAC3D,wCAAwC,EAAE,iCAAiC;AAC7E;;AAEA,uBAAuB;AACvB;EACE,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,sCAAsC;AACxC;;AAEA,kCAAkC;AAClC;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA,wCAAwC;AACxC;EACE,gBAAgB,EAAE,8BAA8B;AAClD","sourcesContent":["/* Sidebar CSS: Sidebar.css */\n\n/* Wrapper for the sidebar */\n.ProSidebar {\n  position: fixed; /* Sidebar stays fixed */\n  top: 0;\n  left: 0;\n  width: 200px; /* Sidebar width */\n  height: 100vh; /* Full height of the viewport */\n  background-color: #111; /* Dark background */\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between; /* Place logout at the bottom */\n  z-index: 100; /* Ensure sidebar stays above other content */\n  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Shadow for better visibility */\n}\n\n/* Sidebar menu items */\n.ProSidebar .menu-item {\n  padding: 15px;\n  color: #fff;\n  text-align: left;\n  transition: background-color 0.3s ease;\n}\n\n/* Hover effect on sidebar items */\n.ProSidebar .menu-item:hover {\n  background-color: #3b3f47;\n  cursor: pointer;\n}\n\n/* Logout button styling (if specific) */\n.ProSidebar .menu-item.logout {\n  margin-top: auto; /* Push logout to the bottom */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
