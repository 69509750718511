import React, { useState } from 'react';
import axiosInstance from '../interceptor/axiosConfig';
import { useNavigate } from 'react-router-dom';

function AddScheduleForm() {
  const [formData, setFormData] = useState({
    title: '',
    artist: '',
    file_path: '',
    scheduled_date: '',
    start_time: '',
    end_time: '',
    mount_url: '',
    mount_state: 'live',
  });
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await axiosInstance.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setFormData((prevFormData) => ({
          ...prevFormData,
          file_path: response.data.filePath,
        }));
      } catch (error) {
        console.error('Error uploading file:', error);
        return;
      }
    }

    try {
      await axiosInstance.post('/schedule/addWithSong', formData);
      navigate('/home'); // Redirige l'utilisateur vers la page d'accueil
    } catch (error) {
      console.error('Error adding schedule:', error);
    }
  };

  return (
    <form className="add-schedule-form" onSubmit={handleSubmit}>
      <input
        type="text"
        name="title"
        placeholder="Song Title"
        value={formData.title}
        onChange={handleChange}
        required
      />
      <input
        type="text"
        name="artist"
        placeholder="Artist"
        value={formData.artist}
        onChange={handleChange}
      />
      <input
        type="file"
        name="file"
        onChange={handleFileChange}
        required
      />
      <input
        type="date"
        name="scheduled_date"
        value={formData.scheduled_date}
        onChange={handleChange}
        required
      />
      <input
        type="time"
        name="start_time"
        value={formData.start_time}
        onChange={handleChange}
        required
      />
      <input
        type="time"
        name="end_time"
        value={formData.end_time}
        onChange={handleChange}
        required
      />
      <input
        type="text"
        name="mount_url"
        placeholder="Mount URL"
        value={formData.mount_url}
        onChange={handleChange}
        required
      />
      <select
        name="mount_state"
        value={formData.mount_state}
        onChange={handleChange}
        required
      >
        <option value="live">Live</option>
        <option value="automatic">Automatic</option>
      </select>
      <button type="submit">Add Schedule</button>
    </form>
  );
}

export default AddScheduleForm;