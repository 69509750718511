import React, { useState, useEffect } from 'react';
import axiosInstance from '../interceptor/axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';

function EditScheduleForm() {
  const [formData, setFormData] = useState({
    title: '',
    artist: '',
    file_path: '',
    scheduled_date: '',
    start_time: '',
    end_time: '',
    mount_url: '',
    mount_state: 'live',
  });
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        const response = await axiosInstance.get(`/schedule/${id}`);
        const scheduleData = response.data;
        setFormData({
          title: scheduleData.Song ? scheduleData.Song.title : '',
          artist: scheduleData.Song ? scheduleData.Song.artist : '',
          file_path: scheduleData.Song ? scheduleData.Song.file_path : '',
          scheduled_date: scheduleData.scheduled_date,
          start_time: scheduleData.start_time,
          end_time: scheduleData.end_time,
          mount_url: scheduleData.mount_url,
          mount_state: scheduleData.mount_state,
        });
      } catch (error) {
        console.error('Error fetching schedule:', error);
      }
    };

    fetchSchedule();
  }, [id]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put(`/schedule/${id}`, formData);
      navigate('/home'); // Redirige l'utilisateur vers la page d'accueil
    } catch (error) {
      console.error('Error updating schedule:', error);
    }
  };

  return (
    <form className="edit-schedule-form" onSubmit={handleSubmit}>
      <input
        type="text"
        name="title"
        placeholder="Song Title"
        value={formData.title}
        onChange={handleChange}
        required
      />
      <input
        type="text"
        name="artist"
        placeholder="Artist"
        value={formData.artist}
        onChange={handleChange}
      />
      <input
        type="text"
        name="file_path"
        placeholder="File Path"
        value={formData.file_path}
        onChange={handleChange}
        required
      />
      <input
        type="date"
        name="scheduled_date"
        value={formData.scheduled_date}
        onChange={handleChange}
        required
      />
      <input
        type="time"
        name="start_time"
        value={formData.start_time}
        onChange={handleChange}
        required
      />
      <input
        type="time"
        name="end_time"
        value={formData.end_time}
        onChange={handleChange}
        required
      />
      <input
        type="text"
        name="mount_url"
        placeholder="Mount URL"
        value={formData.mount_url}
        onChange={handleChange}
        required
      />
      <select
        name="mount_state"
        value={formData.mount_state}
        onChange={handleChange}
        required
      >
        <option value="live">Live</option>
        <option value="automatic">Automatic</option>
      </select>
      <button type="submit">Update Schedule</button>
    </form>
  );
}

export default EditScheduleForm;