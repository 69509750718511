import axios from 'axios';

// Création d'une instance Axios avec la configuration de base
const axiosInstance = axios.create({
  baseURL: 'https://api-dashboard.eglisecdau.com/api', // Assurez-vous que cette URL est correcte
  withCredentials: true,  // Nécessaire si l'API utilise des cookies pour l'authentification
});

// Intercepteur de requêtes pour ajouter le token d'authentification
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Intercepteur de réponses pour gérer les erreurs 401/403 et rediriger si besoin
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403) &&
      window.location.pathname !== '/login'
    ) {
      // Redirection vers la page de login sans possibilité de retour en arrière
      window.location.replace('/login');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
